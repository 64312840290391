import React from 'react'

const Social = () => {
  return (
    <div className="home__social">
        <a href="https://www.linkedin.com/in/papattarada-apithanangsiri/" className="home__social-icon" target="_blank">
            <i class="uil uil-linkedin-alt"></i>
        </a>
        <a href="https://github.com/Punpun1643" className="home__social-icon" target="_blank">
            <i class="uil uil-github-alt"></i>
        </a>
        <a href="mailto:papattarada.a@u.nus.edu" className="home__social-icon" target="_blank">
            <i class="uil uil-envelope-alt"></i>
        </a>
    </div>
  )
}

export default Social